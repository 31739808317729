import React, { Component } from 'react'
import {withRouter} from 'react-router-dom'
import SubLoader from '../Loader/SubLoader'
import {API_HOST} from '../../settings.config.js'
import ErrorHandling from '../../js/CommonFunctions'
import $ from 'jquery'
const axios = require('axios').default;


class CategoryGroups extends Component {
    constructor(props){
        super(props)
        this.state = {
            groups:[],
        }
    }
    componentDidMount(){
        const auth_token = "Token "+localStorage.token
        //$('.category-groups-row').addClass('add-padding')
       // ReactDOM.render(<PageLoader/>,document.getElementById('category_group_loader'))
        axios.defaults.headers.get['Authorization'] =auth_token
        // axios.get('{% url "customer_portal:category_groups" %}',
        axios.get(API_HOST+'/api/items/categories/group/',
            ).then(response => {
            if(response.status == 200 ){
                $('.product-category-group').find('.inline-loader').fadeOut('slow')
               // $('.category-groups-row').removeClass('add-padding')
                this.setState({
                    groups: response.data
                })
            }
        }).catch(response => {
            ErrorHandling(response)
        });
    }
    redirectToItems = (event) => {
        var category_name = $(event.currentTarget).find('input').val()
        this.props.history.push('/items?groupname='+category_name)
    }
    render() {
        const group_elements = this.state.groups.map((group, index) =>{
            return  <div className="category-group" key={index} onClick={this.redirectToItems}>
                <img src={group.thumbnail} name-slug={group.name_slug} />
                <a className="category-group-name">{group.name}</a> 
                <input type="hidden"  name="category_group_name" value={group.name_slug} />               
            </div>
        })    
        return (
            <div className="col-lg-12 product-category-group">
                    <div className="inline-loader" id="category_group_loader">
                        <SubLoader/>
                    </div>
                    <div className="category-groups-row">
                        <div className="row" id="div_category_groups">
                            <h4 className="inline-block categories-header">Categories</h4>
                            <div className="category-groups" id="category_groups">
                                {group_elements}
                            </div>
                        </div>
                    </div>
                </div>
        )
    }
}

export default withRouter(CategoryGroups)